<template>
  <v-card class="d-flex flex-column my-4">
    <v-card-title class="header-top">
      <h3 class="view-title">Valuable Keyword Topics</h3>
      <v-spacer></v-spacer>
      <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </v-card-title>

    <v-data-table
      :search="search"
      :headers="header"
      :items="report"
      :items-per-page="10"
      item-key="word"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      :sort-by="['tf_diff', 'exists_all', 'comp_all']"
      :sort-desc="[true, true, false]"
      multi-sort
      :hide-default-header="true"
      mobile-breakpoint="0"
    >
      <template v-slot:[`header`]="{ h }">
        <thead class="v-data-table-header">
          <tr>
            <th colspan="1" class="text-center parent-header">{{ h }}</th>
            <th colspan="6" class="text-center parent-header">Average</th>
            <th colspan="2" class="text-center parent-header" v-for="item in headers" :key="item.rank">
              <div class="line-column">
                <span v-if="!item.is_comparison">#{{ item.rank }}</span>
                <span v-else>#Compare</span>
                <small>
                  <a :href="item.url" target="_blank">{{ item.domain }}</a>
                </small>
              </div>
            </th>
          </tr>
          <tr>
            <th v-for="item in header" :key="item.value">{{ item.text }}</th>
          </tr>
        </thead>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import store from '@/store'
import { onBeforeMount, ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default {
  props: {
    loading: Boolean,
    report: Array,
    headers: Array,
  },
  setup(props) {
    const search = ref('')

    const googleLink = word => {
      return `https://www.google.com/search?q=${word}`
    }

    const header = computed(() => {
      let heads = []

      let header_list = [
        {
          text: 'Word',
          value: 'word',
          width: '175px',
        },
        {
          text: 'TF Avg All',
          value: 'tfavg_all',
          width: '175px',
        },
        {
          text: 'TF Avg Comp',
          value: 'tfavg_comp',
          width: '175px',
        },
        {
          text: 'All Say It',
          value: 'exists_all',
          width: '175px',
        },
        {
          text: 'US',
          value: 'comp_all',
          width: '175px',
        },
        {
          text: 'And Not US',
          value: 'used_by_all_except_us',
          width: '175px',
        },
        {
          text: 'Difference',
          value: 'tf_diff',
          width: '175px',
        },
      ]

      const sumComp = computed(() => {
        return props.headers.reduce((sum, head) => {
          if (head.is_comparison) {
            return Number(sum + 1)
          }
          return sum
        }, 0)
      })

      props.headers.forEach(el => {
        if (el.is_comparison) {
          if (sumComp.value == 1) {
            heads.push(
              {
                text: 'C',
                value: 'count_100',
              },
              {
                text: 'TF',
                value: 'tfpos_100',
              },
            )
          } else {
            heads.push(
              {
                text: 'C',
                value: 'count_' + el.rank,
              },
              {
                text: 'TF',
                value: 'tfpos_' + el.rank,
              },
            )
          }
        } else {
          heads.push(
            {
              text: 'C',
              value: 'count_' + el.rank,
            },
            {
              text: 'TF',
              value: 'tfpos_' + el.rank,
            },
          )
        }
      })
      header_list.push(...heads)

      return header_list
    })

    return {
      search,
      header,

      googleLink,
    }
  },
}
</script>

<style scoped>
.header-report {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.line-column {
  display: flex;
  flex-direction: column;
}
</style>
