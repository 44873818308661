<template>
  <div>
    <v-card class="cont-table">
      <v-card-title>
        <h3>Heading Analysis</h3>
      </v-card-title>
      <v-expansion-panels v-model="panel" :disabled="disabled" multiple>
        <v-expansion-panel v-for="item in items" :key="item.id">
          <v-expansion-panel-header>
            <div class="item-option">
              <span color="info" size="20" v-if="item.is_comparison || item.rank >= 100">
                <v-chip color="secondary" outlined>{{ 'Comparison' }} </v-chip>
              </span>
              <span color="info" size="20" v-else>
                <v-chip color="secondary" outlined>{{ 'Rank: ' + item.rank }} </v-chip>
              </span>
              <span class="item-title">{{ item.url }}</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="heading-container" v-if="item.h_tags">
              <div
                v-for="(tag, i) in item.h_tags"
                :key="'h_tags_' + i"
                :class="'heading-item ' + String(tag.type).toLowerCase()"
              >
                <div :class="'badge ' + String(tag.type).toLowerCase()">
                  <span>{{ String(tag.type).toUpperCase() }}</span>
                </div>
                <span class="h-text">{{ tag.value }}</span>
              </div>
            </div>
            <div class="heading-container" v-else>
              <div class="heading-item h1" v-for="(h1, i) in item.h1" :key="'h1' + i">
                <div class="badge h1">
                  <span>H1</span>
                </div>
                <span class="h-text">{{ h1 }}</span>
              </div>

              <div class="heading-item h2" v-for="(h2, i) in item.h2" :key="'h2' + i">
                <div class="badge h2">
                  <span>H2</span>
                </div>
                <span class="h-text">{{ h2 }}</span>
              </div>

              <div class="heading-item h3" v-for="(h3, i) in item.h3" :key="'h3' + i">
                <div class="badge h3">
                  <span>H3</span>
                </div>
                <span class="h-text">{{ h3 }}</span>
              </div>

              <div class="heading-item h4" v-for="(h4, i) in item.h4" :key="'h4' + i">
                <div class="badge h4">
                  <span>H4</span>
                </div>
                <span class="h-text">{{ h4 }}</span>
              </div>

              <div class="heading-item h5" v-for="(h5, i) in item.h5" :key="'h5' + i">
                <div class="badge h5">
                  <span>H5</span>
                </div>
                <span class="h-text">{{ h5 }}</span>
              </div>

              <div class="heading-item h6" v-for="(h6, i) in item.h6" :key="'h6' + i">
                <div class="badge h6">
                  <span>H6</span>
                </div>
                <span class="h-text">{{ h6 }}</span>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
  },
  data() {
    return {
      search: '',
      panel: [0],
      disabled: false,
    }
  },
}
</script>

<style scoped>
.item-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  word-break: break-all;
}
.item-title {
  color: #2ecc71;
}
.content-title {
  margin-bottom: 25px;
}
.heading-container {
  width: 100%;
  margin: 20px 0px;
  box-sizing: border-box;
  position: relative !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.heading-container .badge {
  padding: 5px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 10px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading-container .heading-item {
  /* background: #fff; */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 5.6px;
  padding: 10px 20px;
  border-left: #6ba2e5 5px solid;
  margin-left: 80px;
}
.heading-container .heading-item .h-text {
  /* font-weight: bold; */
}
.heading-item.h1 {
  width: 100%;
  margin-left: 0px;
}
.heading-item.h2 {
  width: 97.5%;
  margin-left: 2.5%;
}
.heading-item.h3 {
  width: 95%;
  margin-left: 5%;
}
.heading-item.h4 {
  width: 92.5%;
  margin-left: 7.5%;
}
.heading-item.h5 {
  width: 90%;
  margin-left: 10%;
}
.heading-item.h6 {
  width: 87.5%;
  margin-left: 12.5%;
}
.heading-item .badge.h1 {
  background: #65ca8b;
}
.heading-item .badge.h2 {
  background: #4dcabe;
}
.heading-item .badge.h3 {
  background: #5caaeb;
}
.heading-item .badge.h4 {
  background: #7592f1;
}
.heading-item .badge.h5 {
  background: #fdae61;
}
.heading-item .badge.h6 {
  background: #f46d43;
}

@media (max-width: 767px) {
  .heading-item.h1,
  .heading-item.h2,
  .heading-item.h3,
  .heading-item.h4,
  .heading-item.h5,
  .heading-item.h6 {
    width: 100%;
    margin: 0px;
  }
  .heading-container .heading-item .h-text {
    /* font-weight: bold; */
    font-size: 14px;
  }
  .heading-container .badge {
    width: auto;
    font-size: 14px;
  }
}
</style>
