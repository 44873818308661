<template>
  <v-container v-if="loading" class="d-flex flex-column">
    <view-loader />
  </v-container>
  <v-container v-else class="d-flex flex-column">
    <no-found-report v-if="!!errors" :errors="errors" />

    <template v-else>
      <h2>Semantic Analysis Crawl Results</h2>
      <div class="header-row">
        <div class="header-report">
          <div class="row-text">
            <span>Report Name: {{ report.report_name }} </span>
          </div>
          <div class="row-text">
            <span
              >Keyword:
              <a :href="googleLink(serp_data.keyword)">{{ serp_data.keyword }}</a>
            </span>
          </div>
          <div class="row-text">
            <div class="col-comparison">
              <span v-if="!!serp_data.comp_urls"
                >Comparison URL:
                <a v-for="url in serp_data.comp_urls" :key="url" :href="url" target="_blank">{{ url }}</a>
              </span>
            </div>
          </div>
          <div class="cont-google-report">
            <google-select-dialog :report="report" />

            <div class="text-center" v-if="google_document_ids && google_document_ids.length > 0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon style="margin-right: 5px">mdi-google-drive</v-icon>
                    <span> View Google Report </span>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(item, index) in google_document_ids"
                    :key="index"
                    @click="viewGoogleReport(item.google_document_id)"
                  >
                    <v-list-item-title>{{ item.type }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </div>

        <div class="col-actions text-center">
          <dialog-export :report="report" />
          <dialog-share-report v-if="report && report.id" :report="report" report_type="semantic_report" />
        </div>
      </div>
    </template>

    <all-components v-if="data" :loading="loading" :values="data" />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import ViewLoader from '@/components/spinner/ViewLoader.vue'
import AllComponents from './components/SemanticAllComponents.vue'
import GoogleSelectDialog from './components/GoogleSelectDialog.vue'
import DialogExport from './components/DialogExport.vue'
import DialogShareReport from '@/components/dialog/DialogShareReport.vue'
//components
import NoFoundReport from '@/components/report/NoFoundReport.vue'

export default {
  components: {
    AllComponents,
    ViewLoader,
    GoogleSelectDialog,
    DialogExport,
    DialogShareReport,
    NoFoundReport,
  },
  data() {
    return {
      loading: false,
      errors: null,

      report: {},
      google_document_ids: null,
      data: null,
      serp_data: {},
      hTags: [],
    }
  },
  mounted() {
    this.getReportData()
  },
  methods: {
    ...mapActions({
      getSemanticAnalysisReport: 'semantic_analysis/getSemanticAnalysisReport',
      setSnackbar: 'snackbar/set',
    }),
    async getReportData() {
      this.loading = true
      await this.getSemanticAnalysisReport(
        new URLSearchParams({
          ...this.$route.params,
        }).toString(),
      )
        .then(resp => {
          if (resp && resp.data) {
            this.data = resp.data
            this.report = resp.data.tfidfReport
            this.google_document_ids = resp.data.google_document_ids
            this.serp_data = resp.data.tfidfReport.crawllog.serp_data
            this.setDataTags(resp.data.reportHeaders)
            this.errors = null
          }
        })
        .catch(error => {
          if (error.response.data && error.response.data.status === 'error') {
            this.errors = error.response.data.errors
          } else {
            this.errors = null
          }
          this.setSnackbar({
            status: true,
            text: 'No report found.',
            color: 'error',
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    toGoogleLink(word) {
      window.open(`https://www.google.com/search?q=${word}`, '_blank')
    },
    setDataTags(headers) {
      let h = [
        { data: [], tag: 'H1' },
        { data: [], tag: 'H2' },
        { data: [], tag: 'H3' },
        { data: [], tag: 'H4' },
        { data: [], tag: 'H5' },
        { data: [], tag: 'H6' },
      ]
      headers.forEach((e, i) => {
        if (e.rank >= 100) {
          if (e.h1) h[0].data.unshift({ rank: 'Client', text: e.h1 })
          if (e.h2) h[1].data.unshift({ rank: 'Client', text: e.h2 })
          if (e.h3) h[2].data.unshift({ rank: 'Client', text: e.h3 })
          if (e.h4) h[3].data.unshift({ rank: 'Client', text: e.h4 })
          if (e.h5) h[4].data.unshift({ rank: 'Client', text: e.h5 })
          if (e.h6) h[5].data.unshift({ rank: 'Client', text: e.h6 })
        } else {
          if (e.h1) h[0].data.push({ rank: e.rank, text: e.h1 })
          if (e.h2) h[1].data.push({ rank: e.rank, text: e.h2 })
          if (e.h3) h[2].data.push({ rank: e.rank, text: e.h3 })
          if (e.h4) h[3].data.push({ rank: e.rank, text: e.h4 })
          if (e.h5) h[4].data.push({ rank: e.rank, text: e.h5 })
          if (e.h6) h[5].data.push({ rank: e.rank, text: e.h6 })
        }
      })
      this.hTags = h
    },
    viewGoogleReport(google_document_id) {
      const url = `https://docs.google.com/document/d/${google_document_id}`
      window.open(url, '_blank')
    },
  },
}
</script>

<style scoped>
.header-report {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.header-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.col-comparison {
  display: flex;
  flex-direction: column;
}
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
.cont-google-report {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}
.col-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
