<template>
  <v-card class="my-4">
    <v-card-text>
      <chartjs-component-horizontal-bar-chart
        v-if="datacollection"
        :data="datacollection"
        :options="options"
        :height="200"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ChartjsComponentHorizontalBarChart from '@/components/chart/chartjs/ChartjsComponentHorizontalBarChart.vue'

export default {
  components: {
    ChartjsComponentHorizontalBarChart,
  },
  props: {
    loading: Boolean,
    data: Array,
    labels: Array,
    show_comp_url: Boolean,
  },
  data() {
    return {
      datacollection: null,
      options: {
        responsive: true,
        scales: {
          xAxes: [
            {
              ticks: {
                min: 0.0,
              },
            },
          ],
        },
      },
    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData() {
      const top = []
      const avg = []
      const url = []
      const datasets = []

      this.data.forEach(e => {
        avg.push(e.avg_3_url)
        top.push(e.avg_total_url)
        this.show_comp_url && url.push(e.comp_url)
      })
      datasets.push(
        {
          label: 'Avg Top 1-3 Ranking URLs',
          data: avg,
          backgroundColor: '#3d85c6',
        },
        {
          label: 'Top 10 Avg',
          data: top,
          backgroundColor: '#9fc5e8',
        },
      )

      if (this.show_comp_url) {
        datasets.push({
          label: 'Your URL',
          data: url,
          backgroundColor: '#93c47d',
        })
      }

      this.datacollection = {
        labels: this.labels,
        datasets,
      }
    },
  },
}
</script>

