<template>
  <v-card class="d-flex flex-column my-4">
    <v-card-title>
      <h3>Titles</h3>
      <v-spacer></v-spacer>
      <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="titles"
      :items-per-page="-1"
      item-key="word"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
    </v-data-table>
  </v-card>
</template>


<script>
export default {
  props: {
    loading: Boolean,
    data: Array,
    compareSerp: Object,
  },
  data() {
    return {
      search: '',
      titles: [],
      headers: [
        {
          text: 'Rank',
          value: 'result_position',
        },
        {
          text: 'Page Title',
          value: 'result_title',
        },
      ],
    }
  },
  mounted() {
    this.setDataTitles()
  },
  methods: {
    setDataTitles() {
      this.titles = this.data ? this.data.slice(0, 10) : []
      this.titles.unshift(
        this.compareSerp
          ? { ...this.compareSerp, result_position: this.compareSerp.result_position + ' (Client)' }
          : { result_position: '', result_title: 'Client not found in first 100 results' },
      )
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
