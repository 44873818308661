<template>
  <v-card class="d-flex flex-column my-4">
    <v-card-title>
      <h3>How Many Times It's Used on Average</h3>
      <v-spacer></v-spacer>
      <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="data"
      :items-per-page="10"
      item-key="word"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.action`]="{ item }">
        <span v-if="item.comp_url < item.avg_total_url">Consider ADDING this topic {{ item.difference }} times.</span>
        <span v-else>Consider REMOVING this topic {{ item.difference }} times.</span>
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
export default {
  props: {
    loading: Boolean,
    data: Array,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Word',
          value: 'word',
        },
        {
          text: 'Avg Top 1-3 Ranking URLs',
          value: 'avg_3_url',
        },
        {
          text: 'Avg Top 10 Ranking URLs',
          value: 'avg_total_url',
        },
        {
          text: 'Your URL',
          value: 'comp_url',
        },
        {
          text: 'Difference',
          value: 'difference',
        },
        {
          text: 'Action',
          value: 'action',
        },
      ],
    }
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
