
<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed v-bind="attrs" v-on="on">
          <v-icon style="margin-right: 5px">mdi-google</v-icon>
          <span>Google Report</span>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Select Report </v-card-title>

        <v-card-text>
          <div class="cont-buttons">
            <v-btn
              depressed
              @click="googleReport('97floor')"
            >
              <div class="content-actions">
                <img :src="logo97" class="logo" alt="Base Logo" />
                <span>97thfloor</span>
              </div>
            </v-btn>
            <v-btn
              depressed
              @click="googleReport('basesearchmarketing')"
            >
              <div class="content-actions">
                <img :src="logobase" class="logo" alt="Base Logo" />
                <span>Base Search Marketing</span>
              </div>
            </v-btn>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import logo97 from '@/assets/images/partners/97_logo.webp'
import logobase from '@/assets/images/partners/base_logo.webp'

export default{
  props: {
    report: Object,
  },
  data() {
    return {
      dialog: false,
      logo97,
      logobase,
    }
  },
  methods: {
    googleReport(report_type){
      const url = `${process.env.VUE_APP_BACKEND_URL}/login/google?report_id=${this.report.id}&report_type=${report_type}`;
      window.open(url, '_blank');
    }
  },
}

</script>


<style lang="css" scoped>
.cont-buttons {
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.cont-buttons .v-btn:not(.v-btn--round).v-size--default {
  height: auto !important;
}
.logo {
  height: 50px;
}
.button {
  flex-direction: column;
}
.content-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}
</style>
