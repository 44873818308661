<template>
  <v-card class="d-flex flex-column my-4">
    <v-card-title class="header-top">
      <h3>Semantic Analysis Summary</h3>
      <v-spacer></v-spacer>
      <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </v-card-title>
    <v-data-table
      dense
      :search="search"
      :headers="headers"
      :items="list"
      :items-per-page="-1"
      item-key="rank"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
    </v-data-table>
  </v-card>
</template>


<script>
export default {
  props: {
    loading: Boolean,
    data: Array,
    tag: String,
  },
  data() {
    return {
      search: '',
      list: [],
      headers: [
        {
          text: 'High > 0.6',
          value: 'high',
        },
        {
          text: 'Mid 0.6-0.55',
          value: 'mid',
        },
        {
          text: 'Low < 0.55',
          value: 'low',
        },
      ],
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      let li = []
      let high = []
      let mid = []
      let low = []

      this.data.forEach(e => {
        if (e.tfavg_all > 0.6 && high.length < 15) high.push(e.word)
        if (e.tfavg_all < 0.6 && e.tfavg_all > 0.55 && mid.length < 15) mid.push(e.word)
        if (e.tfavg_all < 0.55 && low.length < 15) low.push(e.word)
      })
      for (let i = 0; i < 15; i++) {
        li.push({
          high: high.length >= i ? high[i] : '',
          mid: mid.length >= i ? mid[i] : '',
          low: low.length >= i ? low[i] : '',
        })
      }
      this.list = li
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
