<template>
  <div>
    <template v-if="data.chartdata">
      <chart-horizontal
        v-if="!!data.chartdata"
        :loading="loading"
        :items="data.chartdata"
        :show_comp_url="!!serp_data.comp_urls"
      />
    </template>

    <template>
      <top-terms-among
        v-if="data.chartdata"
        :loading="loading"
        :report="data.chartdata"
        :headers="data.reportHeaders"
        :keyword="serp_data.keyword"
      />
    </template>

    <template>
      <how-many-times v-if="data.resume_data && data.compareSerp" :loading="loading" :data="data.resume_data" />
    </template>

    <template>
      <div v-if="!!data.resume_data && !!serp_data.comp_urls">
        <avg-resume-chart
          v-if="data.resume_data.length > 0 && serp_data.comp_urls.length > 0"
          :loading="loading"
          :data="data.resume_data"
          :labels="data.resume_labels"
          :show_comp_url="!!serp_data.comp_urls"
        />
      </div>
    </template>

    <template>
      <titles-table
        v-if="data.serpDataResults"
        :loading="loading"
        :data="data.serpDataResults"
        :compareSerp="data.compareSerp"
      />
    </template>

    <template>
      <h-tags-from-page-table v-if="h1_tags && !!h1_tags" :loading="loading" :data="h1_tags" tag="H1" />
    </template>

    <template>
      <div>
        <heading-analysis :loading="loading" :items="data.reportHeaders" />
      </div>
    </template>

    <template>
      <semantic-analysis-summary v-if="data.chartdata" :loading="loading" :data="data.chartdata" />
    </template>

    <template>
      <descripction-table
        v-if="data.serpDataResults"
        :loading="loading"
        :data="data.serpDataResults"
        :compareSerp="data.compareSerp"
      />
    </template>

    <template>
      <rank-url-table
        v-if="data.serpDataResults"
        :loading="loading"
        :data="data.serpDataResults"
        :compareSerp="data.compareSerp"
      />
    </template>

    <template>
      <v-card class="d-flex flex-column my-4">
        <v-card-title class="header-top">
          <h3 class="view-title">Featured Snippets</h3>
        </v-card-title>
        <div class="text-center">
          <v-sheet class="px-8 pt-7 pb-8 mx-auto text-center" color="blue-grey darken-3" dark>
            <div class="grey--text text--lighten-1 text-body-2 mb-8">Does this SERP Contain a Featured Snippet?</div>

            <v-btn :disabled="loading" class="ma-1" color="success" dark @click="toGoogleLink(serp_data.keyword)">
              Link to SERP
            </v-btn>
          </v-sheet>
        </div>
      </v-card>
    </template>
    <template>
      <internal-inliked-opporunities
        v-if="data.internal_urls_not_linkedto_compurl"
        :loading="loading"
        :opportunities="data.internal_urls_not_linkedto_compurl"
      />
    </template>

    <template>
      <report-table-and-not-us
        v-if="data.chartdata"
        :loading="loading"
        :report="data.chartdata"
        :headers="data.reportHeaders"
      />
    </template>

    <template>
      <valuable-keyword-topics
        v-if="data.chartdata"
        :loading="loading"
        :report="data.chartdata"
        :headers="data.reportHeaders"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import AvgResumeChart from './AvgResumeChart.vue'
import ChartHorizontal from './ChartHorizontal.vue'
import DescripctionTable from './DescripctionTable.vue'
import HowManyTimes from './HowManyTimes.vue'
import HTagsFromPageTable from './HTagsFromPageTable.vue'
import InternalInlikedOpporunities from './InternalInlikedOpporunities.vue'
import RankUrlTable from './RankUrlTable.vue'
import ReportTableAndNotUs from './ReportTableAndNotUs.vue'
import SemanticAnalysisSummary from './SemanticAnalysisSummary.vue'
import TitlesTable from './TitlesTable.vue'
import TopTermsAmong from './TopTermsAmong.vue'
import ValuableKeywordTopics from './ValuableKeywordTopics.vue'
import HeadingAnalysis from './HeadingAnalysis.vue'

export default {
  components: {
    ChartHorizontal,
    TopTermsAmong,
    HowManyTimes,
    AvgResumeChart,
    TitlesTable,
    HTagsFromPageTable,
    DescripctionTable,
    RankUrlTable,
    SemanticAnalysisSummary,
    ValuableKeywordTopics,
    ReportTableAndNotUs,
    InternalInlikedOpporunities,
    HeadingAnalysis,
  },
  props: {
    loading: Boolean,
    values: Object,
  },
  data() {
    return {
      data: {},
      report: {},
      google_document_id: null,
      serp_data: {},
      reportHeaders: [],
      h1_tags: [],
    }
  },
  watch: {
    values: {
      immediate: true,
      handler(val, oldVal) {
        this.data = val
        this.report = val.tfidfReport
        this.google_document_id = val.google_document_id
        this.serp_data = val.tfidfReport.serp_data ? val.tfidfReport.serp_data : val.tfidfReport.crawllog.serp_data

        this.h1_tags = []
        val.reportHeaders.forEach(tag => {
          let values = {
            tag: '',
            rank: tag.rank,
            is_comparison: tag.is_comparison,
          }
          if (tag.is_comparison === true || tag.rank > 10) {
            values.is_comparison = true
            values.rank = 'comparison'
          } else {
            values.is_comparison = false
          }
          if (tag.h1) {
            if (tag.h1[0]) {
              values.tag = tag.h1[0]
              this.h1_tags.push(values)
            }
          }
        })
      },
    },
  },
  methods: {
    googleLink(word) {
      return `https://www.google.com/search?q=${word}`
    },
    toGoogleLink(word) {
      window.open(`https://www.google.com/search?q=${word}`, '_blank')
    },
  },
}
</script>
