<template>
  <v-card class="d-flex flex-column my-4">
    <v-card-title>
      <h3>Internal Unlinked Opportunities</h3>
      <v-spacer></v-spacer>
      <v-text-field label="Search" v-model="search" class="search" prepend-icon="mdi-magnify"></v-text-field>
    </v-card-title>
    <v-data-table
      :search="search"
      :headers="headers"
      :items="data"
      :items-per-page="10"
      item-key="word"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      mobile-breakpoint="0"
    >
    </v-data-table>
  </v-card>
</template>


<script>
export default {
  props: {
    loading: Boolean,
    opportunities: Array,
  },
  data() {
    return {
      search: '',
      data: [],
      headers: [
        {
          text: 'URL',
          value: 'url',
        },
      ],
    }
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      let registros = []
      this.opportunities.forEach(e => {
        registros.push({ url: e })
      })
      this.data = registros
    },
  },
}
</script>

<style scoped>
.header-top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.view-title {
  margin-bottom: 20px;
}
.search {
  max-width: 350px;
}
</style>
